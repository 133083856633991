import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import {Layout} from '@components/layout/layout'
import { H1, H3, Paragph } from "@components/layout/style"
import { ListDisc, MainContainer, Question,Response, RightContainer, StyledHero } from "../../components/oferta.style"


const StomatologiaEstetyczna:React.FC = ({data}) => {
  return (<Layout data={data} prop={{
      canonicalUrl: 'https://www.mediroot.pl/oferta/stomatologia-estetyczna',
      title: 'Stomatologia estetyczna | Mediroot',
      description: 'Mediroot ➤ Stomatolog Wągrowiec - Stomatologia zachowawcza ✔ Stomatologia dziecięca ✔ Ortodoncja ✔ Protetyka stomatologiczna ✔ Zapoznaj się z ofertą i zadzwoń już dziś! ☎'
  }}>
    <StyledHero>
      <StaticImage
        src="../../assets/images/hero.jpg"
        placeholder="blurred"
        alt="Hero image"
        layout="fullWidth"/>
    </StyledHero>
    <MainContainer>
      <RightContainer>
        <H1>
        Stomatologia estetyczna
        </H1>

        <Paragph>
        W skład tej dziedziny wchodzi wiele różnego rodzaju zabiegów mających na celu poprawienie wyglądu uzębienia pacjenta.
Człowiek ma genetycznie uwarunkowany kolor zęba,  dodatkowo jesteśmy przez całe życie narażeni na czynniki wpływające na ich naturalny kolor . Przyczynami przebarwień są między innymi wiek pacjenta, choroby ogólne (typu porfirie, choroby hemolityczne), zażywanie antybiotyków z grupy tetracyklin, choroby zgorzelinowe miazgi, preparaty stosowane w leczeniu endodontycznym, a także te zewnątrzpochodne jak palenie tytoniu, barwniki z kawy i herbaty, nadmierne używanie płynów do płukania jamy ustnej z chlorheksydyną oraz niewłaściwa higiena i zaleganie złogów nazębnych.
Warunkiem niezbędnym do osiągnięcia zadowalającego efektu jest współpraca z pacjentem, utrzymywanie higieny jamy ustnej oraz rezygnacja ze szkodliwych nawyków np. palenia papierosów.
        </Paragph>

        <H3>
        Usuwanie przebarwień zębów
        </H3>
        <Paragph>

        Przebarwienia zębów powodują, że uśmiechamy się rzadziej i oszczędniej. Stomatologia estetyczna, jaką oferujemy pacjentom składa się również z zabiegów usuwania przebarwień zębów. Przebarwienia dzielą się na dwa rodzaje – wewnątrzpochodne oraz zewnątrzpochodne.
        </Paragph>
        <Paragph style={{textIndent:0}}>
        Oto jak wygląda przebieg leczenia:
        </Paragph>
        <ListDisc>
          <li>
            Przebarwienia zębów wewnątrzpochodne: na ogół przebarwienia te są wynikiem przyjmowania leków , leczenia kanałowego. By wybielić martwy ząb stomatolog umieszcza w komorze zęba wkładkę wybielającą i zostawia ją na okres 3-5 dni. Tego rodzaju zabieg powtarzany jest 2-3 krotnie po czym zakłada się wypełnienie. Dzięki temu efekt wybielania jest naprawdę trwały. Metoda ta jest jednak stosowana w celu wybielenia pojedynczych zębów.
          </li>
          <li>
          Przebarwienia zębów zewnątrzpochodne: przeważnie przebarwienia są spowodowane czynnikami zewnętrznymi takimi jak: picie kawy, herbaty czy palenie papierosów. W celu usunięcia przebarwień zaleca skorzystanie z zabiegu piaskowania oraz wybielania zębów. Mogą też być wynikiem np. demineralizacji po płytce nazębnej w tym celu używamy preparat ICON.
          </li>
        </ListDisc>

        <H3>
        Wybielanie w gabinecie
        </H3>

        <Paragph>
        Podczas pierwszej wizyty lekarz zajmuje się oczyszczeniem zębów z kamienia i  osadów, następnie nakłada na dziąsła żel ochraniający oraz utwardza go lampą UV. Następnym etapem jest nałożenie na zęby preparatu wybielającego oraz naświetlenie go, by uzyskać lepszy efekt czynność tą powtarza się.
        </Paragph>

        <H3>
        Wybielanie nakładkowe
        </H3>
        <Paragph>
        Podczas pierwszej wizyty  lekarz zajmuje sie usunięciem kamienia oraz osadu nazębnego, następnie lekarz pobiera wyciski na łyżkach wyciskowych.Na kolejnej wizycie Pacjent otrzymuje od lekarza specjalnie przygotowane wcześniej przez technika nakładki na zęby, strzykawki oraz żel wybielający. Zgodnie z instrukcją otrzymaną przez lekarza nakładki należy zakładać na noc .

        </Paragph>

        <H3>
        PYTANIA
        </H3>

        <Paragph style={{
          textIndent:0
        }}>
          <Question>
          Czy wybielanie zębów jest bezpieczne?
          </Question>
          <Response>
          Stomatologia estetyczna, do której wybielanie zębów się zalicza jest tematem niełatwym. Jeśli pacjent wybiela zęby pod opieką lekarza stomatologa wówczas takie wybielanie jest całkowicie bezpieczne dla dziąseł i zębów. Bywa, że po wykonaniu zabiegu wybielania występuje nadwrażliwość zębów, która jest przejściowa i ustępuje zwykle po około 24 godzinach.
          </Response>


          <Question>
          Jakie są przeciwwskazania do wybielania zębów?
          </Question>
          <Response>
          Zębów nie powinny wybielać kobiety ciężarne oraz te w okresie karmienia, ze znacznym uszkodzeniem szkliwa (to powinien ocenić stomatolog)ponadto osoby cierpiące na epilepsję jak i również dzieci poniżej 16 roku życia.
          </Response>


          <Question>
          Jak długo utrzymuje się efekt wybielania?
          </Question>
          <Response>
          W zależności od przyzwyczajeń żywieniowych efekt białych zębów utrzymuje się z reguły od pół roku do 2-3 lat. Ponadto wpływ na biel zębów ma również poziom higieny jamy ustnej, oczywiście zabieg można powtarzać.
          </Response>



          <Question>
          Jak dbać o zęby po wybielaniu?
          </Question>
          <Response>
          Zaraz po zabiegu pacjent zobowiązany jest przestrzegać tzw.  białą dietę około 2 tygodni. W okresie tym powinien wystrzegać się herbaty, papierosów, kawy, ciemnych soków oraz innego rodzaju kolorowych napojów oraz pokarmów.
          </Response>
        </Paragph>


      </RightContainer>
    </MainContainer>


  </Layout>);
};

export default StomatologiaEstetyczna;


export const query = graphql`
  query {
    logo: file(relativePath: { eq: "images/logo3.png" }) {
      childImageSharp {
        fluid( maxHeight: 92) {
          src
          sizes
          srcSet
          srcWebp
          base64
          aspectRatio
        }
      }
    }
  }
  `
